import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import Head from 'views/Head';

const NotFoundPage: FC<PageProps> = () => (
  <>
    <Head title="Nie odnaleziono strony" />
    <h1>Not found</h1>
  </>
);

export default NotFoundPage;
